export const cloudinaryCouplesPhotos = [
  {
    publicId: "couples/uukrszjyjuaez0ahayyp",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/v5hxveyc7dq98vami6mx",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/qdeoby9fci68y9kmpzyj",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/yojmiwf79oed9muycinj",
    height: 750,
    width: 500,
  },
  {
    publicId: "couples/rk3mc3kvju4eyrvcy8pf",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/a8or4pb4qzmlbqbwovse",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/grnrngxitywi2e1za9ne",
    height: 750,
    width: 500,
  },
  {
    publicId: "couples/quovg5wzhmluuyyoogg8",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/xyjym1jliykcpttrhjtq",
    width: 750,
    height: 500,
  },

  {
    publicId: "couples/tvtkyar8gxqlscyjcg9y",
    height: 750,
    width: 500,
  },
  {
    publicId: "couples/kvkdqnra4zn7v2yq5ugq",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/mqwriur7zcalxyie3zay",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/ghme8hbcjnrilctwna0k",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/hc89ejg8ahbzj4asxekh",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/y2fco6stl4bampka9dsq",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/ml3pdjakxi4vw2gfz2ec",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/bbbj0cek3bvfzamitaky",
    height: 750,
    width: 500,
  },
  {
    publicId: "couples/tqy0xcq5sh5bp92ux43s",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/jh6o5xwtdx9ooghngilw",
    width: 750,
    height: 500,
  },
  {
    publicId: "couples/n7fsdm4hvzoqf4wgttmu",
    width: 750,
    height: 500,
  },
];
